import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Photos from '../templates/photos';
export const frontmatter = {
  title: 'Photos',
  description: 'I make films to broaden my view. I take pictures for my movies. Go to Photos for my stills and go to Movies for my movables.'
};
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  frontmatter,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Photos data={frontmatter} mdxType="Photos" />

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      